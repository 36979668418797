import React from "react"
import { Box, Flex, Container, H1, Button } from "components"

import youtubeIcon from "./youtube-icon.png"

const Video = props => {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: 0,
        paddingBottom: "56.25%",
      }}
    >
      <iframe
        src={props.src}
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      ></iframe>
    </div>
  )
}

const VideosPage = () => {
  return (
    <Box as="section" pt={[144, 170]} bg="background">
      {/* Feature Video */}

      <Container py={[25, 50]} bg="backgroundReverse" color="textReverse">
        <Flex flexWrap="wrap">
          <Flex
            width={["100%", null, 1 / 2]}
            p={[0, 3]}
            py={3}
            flexDirection="column"
          >
            <Video src="https://www.youtube.com/embed/HxLfbdUkURg" />
          </Flex>
          <Flex
            width={["100%", null, 1 / 2]}
            p={[0, 3]}
            py={3}
            flexDirection="column"
          >
            <Video src="https://www.youtube.com/embed/qhq8SFSt22Q" />
          </Flex>
          <Flex
            width={["100%", null, 1 / 2]}
            p={[0, 3]}
            py={3}
            flexDirection="column"
          >
            <Video src="https://www.youtube.com/embed/OITk2z6pBW8" />
          </Flex>
          <Flex
            width={["100%", null, 1 / 2]}
            px={[0, 3]}
            py={3}
            flexDirection="column"
          >
            <Video src="https://www.youtube.com/embed/3eINWXXJC6I" />
          </Flex>
          <Flex
            width={["100%", null, 1 / 2]}
            px={[0, 3]}
            py={3}
            flexDirection="column"
          >
            <Video src="https://www.youtube.com/embed/wg3i32G_7wU" />
            <H1 as="h1"></H1>
          </Flex>
        </Flex>
        <Flex justifyContent="center" mt={5}>
          <Button
            as={"a"}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/channel/UCyg1apZOq1KCaSVYoqKgWwQ"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={youtubeIcon}
              style={{ marginRight: 12 }}
              alt="Youtube Icon"
              width="32"
            />
            See more
          </Button>
        </Flex>
      </Container>
    </Box>
  )
}

export default VideosPage
